<template>
  <base-card body="p-0">
    <template #header>معلومات المزاد</template>
    <template #body>
      <div class="table-responsive">
        <table class="table pm-0">
          <tr>
            <th class="bg-light text-secondary">تاريخ الإنتهاء</th>
            <td v-if="finishData">
              <strong v-html="finishData"></strong>
            </td>
          </tr>
          <tr>
            <th class="bg-light text-secondary">السعر الحالي</th>
            <td v-if="!!currentPrice" class="space-x-1">
              <strong>{{ currentPrice }}</strong
              ><span>دينار</span>
            </td>
          </tr>
          <tr>
            <th class="bg-light text-secondary">الحد الأقل للمزاودة</th>
            <td v-if="!!lowestBid" class="space-x-1">
              <strong>{{ lowestBid }}</strong
              ><span>دينار</span>
            </td>
          </tr>
        </table>
      </div>
    </template>
  </base-card>
</template>

<script>
import BaseCard from "@/components/UI/BaseCard";
export default {
  name: "AuctionResults",
  components: {BaseCard},
  props: ['finishData', 'currentPrice', 'lowestBid']
}
</script>

<style scoped>

</style>